.hdr-logo {
  width: rem(85);
  margin-bottom: 0;

  &-link {
    display: flex;
    align-items: center;
    gap: rem(20);
    text-decoration: none;
    color: var(--white);
    text-transform: uppercase;

    font-size: rem(36);
  }

  img {
    display: block;
    width: auto;
    height: rem(70);
  }
}

@include above($tablet) {
  .hdr-logo {
    width: rem(120);
  }
}
