#footer {
  background-color: #494949;
  color: var(--white);
}

.footer {
  &-main {
    padding: rem(20) 0;

    &_wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: rem(30);

      @include below($tablet) {
        grid-template-columns: 1fr;
      }
    }

    &_column {
      &__contact {
        display: flex;
        flex-direction: column;
        gap: rem(10);
      }
    }
  }

  &-legal {
    display: flex;
    gap: rem(30);
    justify-content: space-between;
    align-items: center;
    padding: rem(30) rem(30);
    background-color: black;
    flex-wrap: wrap;

    p {
      margin: 0;
    }
  }
}
