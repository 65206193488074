figure {
  margin: 0;

  img {
    display: block;
    width: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}
