.icn {
  @include size(2rem);

  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: none;
    fill: currentColor;
  }
}
