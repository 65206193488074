.wp-block-button {
  transition: 200ms;
  &.is-style-fill {
    &:hover {
      a {
        background-color: black !important;
      }
    }
  }
}
