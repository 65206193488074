.header {
  position: fixed;
  z-index: 9999;
  width: 100%;
  top: 0;
  background-color: #494949;

  .wrapper {
    padding: rem(10) rem(30);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
