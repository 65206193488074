nav {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.nav-main {
  ul {
    display: flex;
    align-items: center;
    gap: rem(20);

    li {
      a {
        color: var(--white);
        text-decoration: none;
        font-weight: 600;
      }
    }
  }

  .menu-item {
    &-has-children {
      position: relative;
      > ul {
        display: none;
      }
      &:hover {
        > ul {
          position: absolute;
          display: flex;
          flex-direction: column;
          background: var(--grey);
          box-shadow: rem(3) rem(3) rem(20) rgba(0, 0, 0, 0.3);
          padding: 10px;
        }
      }
    }

    &.contact {
      padding: rem(10) rem(18);
      border-radius: rem(30);
      background-color: var(--green);
      transition: 200ms;
      cursor: pointer;
      &:hover {
        background-color: black !important;
      }
    }
  }

  @include below($tablet) {
    display: none;
  }

  &-mobile {
    display: none;

    .hamburger {
      width: rem(29);
      cursor: pointer;
      position: relative;
      height: rem(20);

      .line {
        position: absolute;
        width: 100%;
        height: rem(2);
        transition: 200ms;
        background: white;

        &-first {
          top: 0;
        }

        &-middle {
          bottom: rem(9);
        }

        &-last {
          bottom: 0;
        }
      }
    }

    ul {
      display: none;
    }

    &.active {
      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        left: 0;
        top: rem(90);
        height: calc(100vh - #{rem(90)});
        width: 100%;
        z-index: 999;
        background: var(--grey);
        padding: rem(30);

        li {
          font-size: rem(24);
          display: block;
        }
      }

      .hamburger {
        .line {
          transform-origin: middle;
          bottom: rem(9);
          top: unset;
          &-first {
            transform: rotate(-45deg);
          }

          &-middle {
            transform: rotate(45deg);
          }

          &-last {
            transform: rotate(-45deg);
          }
        }
      }
    }

    @include below($tablet) {
      display: flex;
    }
  }
}
