.wp-block-getwid-banner {
  &__image {
    opacity: 1;
  }

  &__title {
  }
  &__text {
  }
  &__caption {
    // position: absolute;
    // top: 0;
    // &-wrapper {
    //   padding: 2.5rem;
    //   position: absolute;
    //   top: 0;
    //   height: 100%;
    //   width: 100%;
    //   transform: translateY(35%);
    //   transition: all 0.3s ease;
    // }
  }
  &:hover {
    .wp-block-getwid-banner {
      &__image {
        opacity: 0.65;
      }
      &__caption {
        &-wrapper {
          // transform: translateY(0%);
        }
      }
      &__text {
      }
    }
  }
}
