.menu-toggle {
  height: 100%;
  border: none;
  position: relative;
  z-index: 1;
  background-color: transparent;
  transition: var(--menu-transition);
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 0;
  appearance: none;

  &__inner {
    height: 100%;
    display: grid;
    place-items: center;
    flex-shrink: 0;
  }
}

@include above($tablet) {
  .menu-toggle {
    width: var(--ui-bar-width);
    border-left: 1px solid var(--header-border-color);

    &__inner {
      width: calc(var(--ui-bar-width) + var(--site-padding));
      margin-right: calc(var(--site-padding) * -1);
    }

    &.is-expanded {
      border-color: transparent;
    }
  }
}
