h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: 'Myriad Pro', sans-serif;
}

h1 {
  font-size: rem(72);
  @include below($tablet-xs) {
    font-size: rem(42);
  }
}
