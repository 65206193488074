.icn-menu {
  width: rem(30);
  height: rem(30);
  display: block;
  position: relative;

  &__inner {
    top: rem(14);
    transition: background-color 0s var(--menu-transition-easing) calc(var(--menu-transition-timing) * 2);

    &,
    &::before,
    &::after {
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      background-color: black;
      border-radius: 1px;
    }

    &::before,
    &::after {
      content: '';
      transition: top var(--menu-transition-timing) var(--menu-transition-easing) calc(var(--menu-transition-timing) * 2), width var(--menu-transition-timing) var(--menu-transition-easing), transform var(--menu-transition-timing) var(--menu-transition-easing) var(--menu-transition-timing);
    }

    &::before {
      top: rem(-8);
      width: 50%;
      right: 0;
    }

    &::after {
      top: rem(8);
      width: 50%;
    }
  }
}


.menu-toggle:hover {
  .icn-menu {
    &__inner::before,
    &__inner::after {
      width: 100%;
    }
  }
}





.menu-toggle[aria-expanded="true"] {
  .icn-menu {
    &__inner {
      background-color: transparent;
      transition: background-color 0s var(--menu-transition-easing) var(--menu-transition-timing);

      &::before,
      &::after {
        width: 100%;
        top: 0;
        transition: top var(--menu-transition-timing) var(--menu-transition-easing), width var(--menu-transition-timing) var(--menu-transition-easing), transform var(--menu-transition-timing) var(--menu-transition-easing) var(--menu-transition-timing);
      }

      &::before {
        transform: rotate(-45deg);
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }
}
