.contact {
  &_section {
    .wp-block-media-text {
      .wp-block-media-text {
        &__content {
          padding-top: rem(150);
          padding-bottom: rem(150);
          background-color: #e3e3e3;
        }
      }
    }
  }

  .wpcf7 {
    &-text,
    &-textarea {
      padding: rem(8);
      border-radius: rem(8);
      border: 1px solid black;
      width: 100%;
      max-width: rem(400);
    }
    &-submit {
      padding: rem(10) rem(18);
      border-radius: rem(30);
      background-color: var(--green);
      color: white;
      cursor: pointer;
      font-weight: 600;
      border: none;
    }
  }
}
