body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: rem(90);
  font-family: 'Myriad Pro', sans-serif;

  .main {
    flex: 1 0 auto;
  }

  .header,
  .footer {
    flex-shrink: 0;
  }

  &.admin-bar {
    min-height: calc(100vh - var(--wp-admin-bar));
  }
}

.constrict {
  max-width: var(--site-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--site-padding);
  padding-right: var(--site-padding);
}
.constrict-xl {
  max-width: var(--site-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--site-padding);
  padding-right: var(--site-padding);
}

.section {
  &.margin-top-regular {
    margin-top: var(--section-margin);
  }

  &.margin-bottom-regular {
    margin-bottom: var(--section-margin);
  }

  &.margin-top-small {
    margin-top: var(--grid-gap);
  }

  &.margin-bottom-small {
    margin-top: var(--grid-gap);
  }

  &.margin-regular {
    margin-top: var(--section-margin);
    margin-bottom: var(--section-margin);
  }

  &.margin-small {
    margin-top: var(--grid-gap);
    margin-bottom: var(--grid-gap);
  }
}
